<template>
  <div class="onboarding-swiper-wrapper">
    <h2 class="onboarding-content-title">Try it or skip it?</h2>
    <p class="text-center">Swipe left if it's a no.</p>

    <OnboardingSwiper
      :list="list"
      @finish="$router.push('/onboarding/outdoorsy')"
    />
  </div>
</template>

<script>
import OnboardingSwiper from '../../components-rf/onboarding/OnboardingSwiper.vue'

const list = [
  {
    id: 1,
    src: require('@/assets/onboarding/places/beach.png'),
    text: 'Lots of big blue. And tiny rocks.',
  },
  {
    id: 2,
    src: require('@/assets/onboarding/places/jungle.png'),
    text: 'Jungle greenery gone wild.',
  },
  {
    id: 3,
    src: require('@/assets/onboarding/places/city.png'),
    text: 'Hustle and glow.',
  },
  {
    id: 4,
    src: require('@/assets/onboarding/places/desert.png'),
    text: 'Dry desert heat with history.',
  },
  {
    id: 5,
    src: require('@/assets/onboarding/places/farm.png'),
    text: 'Fields. And maybe cows. Maybe.',
  },
]

export default {
  name: 'OnboardingPlaces',
  components: { OnboardingSwiper },
  data() {
    return { list }
  },
}
</script>

<style lang="scss" scoped></style>
